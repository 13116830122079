import moment from 'moment-timezone/builds/moment-timezone-with-data';

import {
    DATE_SHORT_TIME_WITH_TIMEZONE,
    MAIN_DATE_FORMAT,
    MAIN_TIME_FORMAT,
    SHORT_TIME_WITH_TIMEZONE,
    TIME_API_FORMAT,
} from '@/constants/timeFormats';

type DateParam = moment.MomentInput;
type DurationParam = moment.DurationInputArg1;
type FormatParam = moment.MomentFormatSpecification;

const getDateTimeFormats = () => {
    //TODO MAIN_DATE_FORMAT and MAIN_TIME_FORMAT will be taken from configurations (from userReducer) in future
    return {
        DATE_FORMAT: MAIN_DATE_FORMAT,
        TIME_FORMAT: MAIN_TIME_FORMAT,
        NEW_DATE_TIME_FORMAT: `${MAIN_DATE_FORMAT} ${MAIN_TIME_FORMAT}`,
        DATE_AT_TIME_FORMAT: `MM/DD [at] ${MAIN_TIME_FORMAT}`,
        DATE_TIME_FORMAT: `YYYY-MM-DD ${MAIN_TIME_FORMAT}`,
    };
};

const {DATE_FORMAT, TIME_FORMAT, DATE_TIME_FORMAT} = getDateTimeFormats();

const formatDateTime = (date: DateParam, resultFormat: string, inputFormat?: FormatParam) => {
    return moment(date, inputFormat).format(resultFormat);
};

const formatDateTimeToLocal = (date: DateParam, resultFormat: string, inputFormat?: FormatParam) => {
    return moment.utc(date, inputFormat).local().format(resultFormat);
};

const formatDateTimeFromGivenToUTC = (
    date: string,
    resultFormat: string,
    inputFormat: FormatParam,
    inputTimeZone = 'America/New_York',
) => moment.tz(date, inputFormat, inputTimeZone).utc().format(resultFormat);

const formatDateTimeToPatient = (
    date: DateParam,
    resultFormat = TIME_FORMAT,
    inputFormat: FormatParam | null = null,
    patientTimeZone = 'America/New_York',
    isUTC?: boolean,
) => {
    const momentDate = moment(date, inputFormat);
    const dateForFormat = isUTC ? momentDate.utc(true) : momentDate;

    return dateForFormat.tz(patientTimeZone).format(resultFormat);
};

const getDateWithText = (date?: DateParam, skipDate = false) => {
    const momentDate = moment(date || new Date());
    const REFERENCE = moment();
    const TODAY = REFERENCE.clone().startOf('day');
    const TOMORROW = REFERENCE.clone().add(1, 'days').startOf('day');
    const YESTERDAY = REFERENCE.clone().subtract(1, 'days').startOf('day');
    const isToday = () => {
        return momentDate.isSame(TODAY, 'd');
    };
    const isTomorrow = () => {
        return momentDate.isSame(TOMORROW, 'd');
    };
    const isYesterday = () => momentDate.isSame(YESTERDAY, 'd');

    if (isToday()) return 'Today';
    if (isYesterday()) return 'Yesterday';
    if (isTomorrow()) return 'Tomorrow';
    return skipDate ? '' : momentDate.format(DATE_FORMAT);
};

const getDurationInDays = (startDate: DateParam, endDate: DateParam, zeroStart = false) =>
    moment(endDate).diff(moment(startDate), 'days') + (zeroStart ? 0 : 1);

const getDurationInHours = (startDate: DateParam, endDate: DateParam, format: FormatParam) =>
    moment(endDate, format).diff(moment(startDate, format), 'hours');

const getDurationInMinutes = (startDate: DateParam, endDate: DateParam, format: FormatParam = TIME_API_FORMAT) =>
    moment(endDate, format).diff(moment(startDate, format), 'minutes');

const getDurationTime = (startDate: DateParam, endDate: DateParam) => {
    const diffTime = moment(endDate).diff(startDate);
    const duration = moment.duration(diffTime);

    const timeFormat = (time: number) => (time >= 10 ? time : `0${time}`);

    const hrs = timeFormat(duration.hours()),
        mins = timeFormat(duration.minutes()),
        secs = timeFormat(duration.seconds());

    return `${hrs}h:${mins}m:${secs}s`;
};

const getFormattedDurationInMinutes = (duration: DurationParam) =>
    `${moment.duration(duration, 'seconds').asMinutes().toFixed(0)} minutes`;

const callDurationFormatter = (seconds: number) => {
    let formattedTime = '';
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;

    if (minutes) {
        formattedTime += `${minutes}m `;
    }

    if (remainingSeconds) {
        formattedTime += `${remainingSeconds}s`;
    }

    return formattedTime.trim();
};

const secondsToHms = (duration: number, long?: boolean, minimized?: boolean) => {
    if (!duration) {
        return '-';
    }

    const sec = Math.floor((+duration % 3600) % 60);
    const min = Math.floor((+duration % 3600) / 60);
    const hour = Math.floor(+duration / 3600);
    const day = Math.floor(hour / 24);
    const month = Math.floor(day / 30);
    const year = Math.floor(month / 12);

    const isPlural = (time: number) => time > 1;

    if (year) {
        return long ? `${year} ${isPlural(year) ? 'years' : 'year'}` : `${year} ${isPlural(year) ? 'yrs' : 'yr'}`;
    } else if (month) {
        return long ? `${month} ${isPlural(month) ? 'months' : 'month'}` : `${month} ${isPlural(month) ? 'mos' : 'mo'}`;
    } else if (day) {
        return long ? `${day} ${isPlural(day) ? 'days' : 'day'}` : `${day} d`;
    } else if (hour) {
        const defineLongMinView = isPlural(min) ? 'minutes' : 'minute';
        const defineShortMinView = minimized ? 'm' : 'min';
        const defineHourView = long ? (isPlural(hour) ? 'hours' : 'hour') : 'h';
        const defineMinView = long ? defineLongMinView : defineShortMinView;
        return `${hour} ${defineHourView} ${min ? `${min} ${defineMinView}` : ''}`;
    } else if (min) {
        const defineLongMinView = isPlural(min) ? 'minutes' : 'minute';
        const defineShortMinView = minimized ? 'm' : 'min';
        const defineMinView = long ? defineLongMinView : defineShortMinView;
        return `${min} ${defineMinView}`;
    } else {
        const defineSecView = long ? (isPlural(sec) ? 'seconds' : 'second') : 's';
        return `${sec} ${defineSecView}`;
    }
};

const durationToSeconds = (duration: DurationParam) => moment.duration(duration).as('seconds');

const durationInMinutes = (duration: DurationParam) => moment.duration(duration).asMinutes();

const secondsToDuration = (seconds: DurationParam) => moment.duration(seconds, 'seconds');

const isAfter = (checkedValue: DateParam, originValue: DateParam, format?: FormatParam) =>
    moment(checkedValue, format).isAfter(moment(originValue, format));

const isBefore = (checkedValue: DateParam, originValue: DateParam, format?: FormatParam) =>
    moment(checkedValue, format).isBefore(moment(originValue, format));

const isSameOrAfter = (checkedValue: DateParam, originValue: DateParam, format?: FormatParam) =>
    moment(checkedValue, format).isSameOrAfter(moment(originValue, format));

const isSameOrBefore = (checkedValue: DateParam, originValue: DateParam, format?: FormatParam) =>
    moment(checkedValue, format).isSameOrBefore(moment(originValue, format));

const formatToMilitaryTime = (time: DateParam) => moment(time, TIME_FORMAT).format(TIME_API_FORMAT);

const isSameDay = (
    firstDate: DateParam,
    secondDate: DateParam,
    firstDateFormat: FormatParam = DATE_TIME_FORMAT,
    secondDateFormat: FormatParam = DATE_TIME_FORMAT,
) =>
    firstDate && secondDate
        ? moment(firstDate, firstDateFormat).isSame(moment(secondDate, secondDateFormat), 'day')
        : false;

const formatToPatientTime = (time: DateParam, isTime: boolean, resultFormat = TIME_FORMAT, patientTimezone: string) =>
    time
        ? formatDateTimeToPatient(
              time,
              resultFormat,
              isTime ? SHORT_TIME_WITH_TIMEZONE : DATE_SHORT_TIME_WITH_TIMEZONE,
              patientTimezone,
          )
        : '';

const getTimeRangeBasedOnDuration = (
    startDateTime: DateParam,
    duration: DurationParam,
    patientTimezone: string,
    inputFormat = DATE_SHORT_TIME_WITH_TIMEZONE,
) => {
    const formattedDuration = durationInMinutes(duration);
    const startDateTimeWithPatientTimezone = formatDateTimeToPatient(
        startDateTime,
        TIME_FORMAT,
        inputFormat,
        patientTimezone,
    );

    const calculatedEndTime = moment(startDateTimeWithPatientTimezone, TIME_FORMAT)
        .add(formattedDuration, 'minutes')
        .format(TIME_FORMAT);

    return `${startDateTimeWithPatientTimezone} - ${calculatedEndTime}`;
};

const getDateUserFormat = (date: DateParam) =>
    `${formatDateTime(date, DATE_FORMAT)}    ${formatDateTime(date, TIME_FORMAT)}`;

const getDateTimeOfDay = (date: DateParam) => {
    const time = +moment(date).format('HHmm');

    if (time >= 6_00 && time <= 11_59) {
        return 'morning';
    }
    if (time >= 12_00 && time <= 16_59) {
        return 'afternoon';
    }
    if (time >= 17_00 && time <= 23_59) {
        return 'evening';
    }
    if (time >= 0 && time <= 5_59) {
        return 'night';
    }
    return '';
};

export {
    callDurationFormatter,
    durationInMinutes,
    durationToSeconds,
    formatDateTime,
    formatDateTimeFromGivenToUTC,
    formatDateTimeToLocal,
    formatDateTimeToPatient,
    formatToMilitaryTime,
    formatToPatientTime,
    getDateTimeFormats,
    getDateTimeOfDay,
    getDateUserFormat,
    getDateWithText,
    getDurationInDays,
    getDurationInHours,
    getDurationInMinutes,
    getDurationTime,
    getFormattedDurationInMinutes,
    getTimeRangeBasedOnDuration,
    isAfter,
    isBefore,
    isSameDay,
    isSameOrAfter,
    isSameOrBefore,
    secondsToDuration,
    secondsToHms,
};
